<template>
  <div class="bundle-styling-row">
    <div class="bundle-styling-col" v-for="(product, i) in localProducts" :key="`product-${i}`">
      <div class="bundle-styling-prod-card">
        <router-link :to="`/product/${product?.slug || $helpers.format.slug(`${product.name} ${product.id}`)}`">
          <b-img fluid center
            :class="{'p-4 default-image' : product.product_images[0].thumbnail_image_url.indexOf('default-image') >= 0}"
            :alt="product.name"
            :title="product.name"
            :src="(img => img.thumbnail_image_url || img.medium_image_url)(product.product_images[0])"
            @error="replaceByDefaultImage(product.product_images[0])"
          />
        </router-link>
        <small class="text-boxed less-line-height mt-1 text-center">
          <b>{{ product.brand_name }}</b><br/>
          {{ product.name }}
        </small>
        <small class="lesser-line-height pt-1">
          Color: <b>{{ (name => name.charAt(0) == '-' ? '-' : name)
            (product.product_detail_variance.map(el => el.color.name).filter((v, i, a) => a.indexOf(v) === i).join(", ").replace(/--none--/g, "-") || "-")
          }}</b><br/>
          Size: <b>{{ (name => name.charAt(0) == '-' ? '-' : name)
            (product.product_detail_variance.map(el => el.size.international).filter((v, i, a) => a.indexOf(v) === i).join(", ").replace(/--none--/g, "-") || "-")
          }}</b>
        </small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductRow",
  props: ["products"],
  data() {
    return {
      localProducts: []
    }
  },
  mounted() {
    this.mapProducts(this.products)
  },
  methods: {
    mapProducts(data) {
      if (data)
        this.localProducts = data.map(el => {
          if (el.product_variance_attribute) {
            el.product.product_detail_variance = [el.product_variance_attribute]
            return el.product
          }
          return el
        })
    },
    replaceByDefaultImage(img) {
      const src = require("@/assets/img/default-image.png");
      if (img.target) img.target.src = src
      else img.thumbnail_image_url = src
    },
  },
  watch: {
    products(newVal) {
      this.mapProducts(newVal)
    }
  }
}
</script>
<style scoped>
.bundle-styling-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: visible;
  padding: 10px;
  margin: -10px;
  padding-top: 15px; /* Add space between accordion title & accordion body (ProductRow.vue) */
}
.bundle-styling-row > .bundle-styling-col {
  flex: 0 0 38%;
  max-width: 38%;
}
@media (min-width: 992px) {
  .bundle-styling-row > .bundle-styling-col {
    flex-basis: 16.666667%;
    max-width: 16.666667%;
  }
  /* For order detail page only */
  .order-additional-info .bundle-styling-row > .bundle-styling-col {
    flex-basis: 12%;
    max-width: 12%;
  }
}
.bundle-styling-col {
  padding-right: .25rem;
  height: 100%;
}
.bundle-styling-prod-card {
  border: 1px solid #dee2e6;
  border-radius: .5rem;
  background: #fff;
  padding: .25rem;
  box-shadow: 3px 3px 3px rgb(0 0 0 / 15%);
}
.bundle-styling-prod-card:nth-child(1) img {
  border-radius: .5rem;
}
</style>
